<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách sự kiện nội bộ</h3>
      </div>
      <div class="card-title">
        <router-link
            v-if="currentUser.user.permission.includes(permission.SU_KIEN_NOI_BO)"
            class="font-weight-bold font-size-3 btn btn-success"
            :to="{ name: 'company-event-create' }"
        ><i class="fa fa-plus"></i>Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="example mb-10">
        <div class="mt-4  row filter_form">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
                                <span class="input-group-text line-height-0 py-0">
                                    <span class="svg-icon">
                                        <i class="fa fa-search"></i>
                                    </span>
                                </span>
              </div>
              <input type="text" class="form-control" placeholder="Tìm kiếm" v-model="query.keyword"
                     @input="search"
                     aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary font-weight-bold mr-2" @click="searchData()">
                  <i class="fa fa-search" v-if="!is_disable_search"></i> Tìm kiếm
                </button>
              </div>
            </div>
          </div>
        </div>
        <DoubleScrollbar class="example-preview table-responsive overflow-auto">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <td>STT</td>
              <th class="" scope="col">Tên sự kiện</th>
              <th class="" scope="col">Mô tả sự kiện</th>
              <th class="min-w-120px" scope="col">Ngày tổ chức</th>
              <th class="min-w-100px" scope="col">Trạng thái</th>
              <th class="min-w-150px" scope="col">Thời gian check-in</th>
              <th class="min-w-200px" scope="col">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in company_events" :key="index">
              <td>{{ paging.current_page == 1 ? index + 1 : (paging.current_page - 1) * 20 + index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.start_date | formatDate }}</td>
              <td>
                                    <span class="badge"
                                          v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                                        {{ item.status === 1 ? "Hiển thị" : 'Ẩn' }}
                                    </span>
              </td>
              <td>
                {{ item.time_checkin | formatTime }}
              </td>
              <td>
                <el-tooltip content="Danh sách người tham gia" placement="top">
                  <router-link :to="{ name: 'company-event-subscribers', params: {id : item.id} }" title="Sửa"
                               v-if="currentUser.user.permission.includes(permission.SUA_NHAN_SU_CO_SO)"
                               class="btn btn-icon mr-2 btn-outline-warning btn-xs"><i class="fas fa-user-plus"></i>
                  </router-link>
                </el-tooltip>
                <router-link :to="{ name: 'company-event-edit', params: {id : item.id} }" title="Sửa"
                             v-if="currentUser.user.permission.includes(permission.SUA_NHAN_SU_CO_SO)"
                             class="btn btn-icon mr-2 btn-outline-info btn-xs"><i class="fas fa-pen-nib"></i>
                </router-link>
                <button
                    title="Xóa"
                        @click="deleteCompanyEvent(item.id)"
                        v-if="currentUser.user.permission.includes(permission.XOA_NHAN_SU_CO_SO) && !item.is_checked"
                        class="btn btn-icon btn-outline-danger btn-xs">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </DoubleScrollbar>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="paging.current_page"
          :page-count="paging.last_page"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :page-class="'page-item'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import FileUpload from 'vue-upload-component';
import DoubleScrollbar from '@/view/content/DoubleScrollbar';
import moment from 'moment-timezone';
import {
  COMPANY_EVENT_DELETE,
  COMPANY_EVENT_GET_LIST
} from "../../../core/services/store/company-event/company-event.module";

const _ = require('lodash');

export default {
  components: {
    FileUpload,
    DoubleScrollbar
  },
  name: "EventCompanyList",
  data() {
    return {
      query: {
        keyword: '',
        start_date: '',
        page: 0,
      },
      paging: {
        count: 0,
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
      },
      classSpan: {
        success: 'badge-success',
        danger: 'badge-danger',
        primary: 'badge-primary',
        secondary: 'badge-secondary'
      },
      company_events: [],
      //Cache
      is_disable_search: false
    }
  },
  created() {
    this.getList();
    this.setBreadcrumb();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Danh sách sự kiện nội bộ", route: 'company-event-list'},
        {title: "Danh sách sự kiện nội bộ"}
      ]);
    },

    getList() {
      this.page = parseInt(this.$route.query.page)
      this.pullParamsUrl();
      this.callApiGetList();
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'company-event-list',
        query: {
          keyword: this.query.keyword,
          start_date: this.query.start_date,
          page: this.query.page
        }
      })
    },
    pullParamsUrl() {
      this.query.keyword = this.$route.query.keyword;
      this.query.start_date = this.$route.query.start_date;
      this.query.page = this.$route.query.page;
    },
    search: _.debounce(function () {
      this.query.status = this.$route.query.status
      this.pushParamsUrl()
      this.page = 1
      this.callApiGetList()
    }, 200),
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: 'list', query: {...this.query, page: this.page}})
      this.callApiGetList()
    },
    searchData() {
      this.pushParamsUrl();
      this.callApiGetList();
    },
    callApiGetList() {
      this.$store.dispatch(COMPANY_EVENT_GET_LIST, {
        keyword: this.query.keyword,
        start_date: this.query.start_date,
        page: this.page ? this.page : 1,
      }).then((res) => {
        this.company_events = res.data
        this.paging = res.pagination
      }).finally(() => {
      });
    },
    deleteCompanyEvent(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(COMPANY_EVENT_DELETE, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
  }
}
</script>
